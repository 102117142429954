// **************************************************
//  Image Gallery
// **************************************************

@import '../../../scss/import';

// Style
.image-gallery {
	display: grid;
	grid-gap: 20px;
	width: 100%;
	position: relative;
	z-index: 10;
	max-width: 1228px;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(4, 1fr);

	// Tablet P
	@media #{$breakpoint-sm-only} {
		grid-template-columns: repeat(2, 1fr);
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		grid-template-columns: 1fr;
	}

	// ###########################################################

	&-wrapper {
		width: 100%;
	}

	// ###########################################################

	&-card {
		position: relative;
		cursor: pointer;

		// ###########################################################

		* {
			pointer-events: none;
		}

		// ###########################################################

		&__image {
			position: relative;
			width: 100%;
			overflow: hidden;
			height: 0;
			padding-bottom: 100%;

			// ###########################################################
			// Overlay

			&::after {
				@extend %content;
				z-index: 3;
				background-color: rgba($cgp_white, 0.75);
				pointer-events: none;
				opacity: 0;
				transition: opacity 0.3s ease;
			}

			// ###########################################################

			.image-aspect {
				transition: transform 0.6s ease;
			}

			// ###########################################################

			i {
				display: block;
				z-index: 4;
				@include horizontal-vertical-align(absolute);
				pointer-events: none;
				opacity: 0;
				transition: opacity 0.3s ease;

				// Mobile
				@media #{$breakpoint-xs-only} {
					height: 30px;
					width: 30px;
				}
			}
		}

		// ###########################################################

		&__caption {
			margin-top: 8px;
			font-style: italic;
			padding-right: 10px;
		}

		// ###########################################################

		&__number {
			display: none;
			position: absolute;
			z-index: 4;
			top: 22px;
			right: 18px;
			font-weight: 700;
			color: var(--color-grey-light);

			// Mobile
			@media #{$breakpoint-xs-only} {
				top: 10px;
				right: 10px;
			}
		}

		// ###########################################################

		&:nth-child(n + 5) {
			display: none;
		}

		// ###########################################################

		&:nth-child(n + 4) {
			.image-gallery-card__number {
				display: block;
			}
		}

		// ###########################################################

		&:hover {
			.image-aspect {
				transform: scale(1.1);
			}

			.image-gallery-card__image {
				i,
				&::after {
					opacity: 1;
				}
			}
		}
	}
}
