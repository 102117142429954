// ##################################################
// Cookie banner
// ##################################################

@import '../../../scss/import';

// Style
.cookie-banner {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding: 40px 0;
	box-shadow: $box_shadow_secondary;
	z-index: $zindex_cookie_notice;
	color: var(--color-black);
	background-color: var(--color-white);
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s ease, visibility 0s linear 0.5s;
	max-height: $vh_100;
	overflow: auto;

	// If Cookie is not set
	&.cookie-not-set {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		transition: opacity 0.5s ease, visibility 0s linear;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 30px 0;
	}

	// ##################################################

	// Width
	.cookie-width {
		display: flex;
		justify-content: space-between;
		align-items: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex-direction: column;
		}
	}

	// ##################################################

	// Text
	// &__text {}

	// ##################################################

	// Button
	&__button {
		margin-left: 50px;
		flex-shrink: 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-left: 0;
			margin-top: 20px;
			width: 100%;
		}
	}

	// ##################################################

	.btn--cookie {
		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 100%;
		}
	}
}
