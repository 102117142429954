//**********************************************************
// 404
//**********************************************************

@import '../../../scss/import';

.page-404 {
	// ##################################################

	&__title {
		color: var(--color-darkblue);
		@extend %fontsize120;
	}

	// ##################################################

	&__content {
		width: 100%;
		display: grid;
		grid-gap: 20px;
		max-width: 700px;
	}
}

.sc-page-404 {
	min-height: 774px;
	padding: 30px 0;
	display: flex;
	align-items: center;
	justify-content: center;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		min-height: calc(var(--vh, 1vh) * 100 - 69px);
	}
}
