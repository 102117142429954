// ##################################################
// Margin
// ##################################################

@import '../../../scss/import';

// Style
.margin {
	display: block;
	width: 100%;

	// ##################################################

	&--100 {
		height: 100px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 70px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
		}
	}

	// ##################################################

	&--90 {
		height: 90px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 70px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
		}
	}

	// ##################################################

	&--80 {
		height: 80px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 60px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
		}
	}

	// ##################################################

	&--76 {
		height: 76px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 56px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
		}
	}

	// ##################################################

	&--70 {
		height: 70px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
		}
	}

	// ##################################################

	&--64 {
		height: 64px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			height: 40px;
		}
	}

	// ##################################################

	&--60 {
		height: 60px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			height: 40px;
		}
	}

	// ##################################################

	&--56 {
		height: 56px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			height: 36px;
		}
	}

	// ##################################################

	&--50 {
		height: 50px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			height: 30px;
		}
	}

	// ##################################################

	&--46 {
		height: 46px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 26px;
		}
	}

	// ##################################################

	&--40 {
		height: 40px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 20px;
		}
	}

	// ##################################################

	&--30 {
		height: 30px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 20px;
		}
	}

	// ##################################################

	&--20 {
		height: 20px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 15px;
		}
	}
}
