// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

// Style
.footer {
	color: $cgp_white;
	background-color: $cgp_blue;
	padding: 76px 0;
	position: relative;
	width: 100%;
	font-size: 14px;
	z-index: 2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 52px 0;
	}

	// **************************************************

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
	}

	// **************************************************

	&__left {
		flex-shrink: 0;
		text-align: center;
	}

	// **************************************************

	&__right {
		display: flex;
		flex-wrap: wrap;
		gap: 4px 20px;
	}

	// **************************************************

	&__item {
		position: relative;

		&:hover {
			text-decoration: underline;
		}

		&:after {
			content: '|';
			position: absolute;
			top: 0;
			bottom: 0;
			right: -12px;
			margin: auto;
			display: block;
		}

		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}
}
