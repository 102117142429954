// **************************************************
// Hero banner
// **************************************************

@import '../../../scss/import';

// Style
.hero-banner {
	position: relative;
	padding: 140px 0 76px;
	width: 100%;
	color: $cgp_white;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	// Desktop S
	@media #{$breakpoint-lg-only} {
		padding: 120px 0 60px;
	}

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		padding: 120px 0 60px;
		background-position: right 20% center;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 112px 0 40px;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		transform: rotate(-180deg);
		background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
		opacity: 0.54;
		pointer-events: none;
		z-index: 0;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			height: 100%;
		}
		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 33%;
		background-image: linear-gradient(180deg, #004990 0%, rgba(0, 73, 144, 0) 100%);
		opacity: 0.75;
		pointer-events: none;
		z-index: 0;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			display: none;
		}
	}

	&__bg {
		img {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			height: 100%;
			width: 100%;
			object-fit: cover;
			pointer-events: none;
			z-index: -1;

			&.destkop {
				display: block;

				// Tablet L and down
				@media #{$breakpoint-md-down} {
					object-position: right;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					display: none;
				}
			}
			&.mobile {
				display: none;
				object-position: top;

				// Mobile
				@media #{$breakpoint-xs-only} {
					display: block;
				}
			}
		}
	}

	// **************************************************

	.title {
		color: $cgp_white;
	}

	// **************************************************

	&__content {
		position: relative;
		z-index: 1;
	}

	// **************************************************

	&__subtitle {
		max-width: 540px;

		.title {
			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 13px;
			}
		}
	}

	// **************************************************

	&__title {
		max-width: 600px;
		margin-top: 20px;
		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 200px;
		}

		.title > * {
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
		}

		.title {
			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 28px;
			}
		}
	}

	// **************************************************

	&__intro-text {
		max-width: 540px;
		margin-top: 40px;
		padding: 24px 0;
		text-transform: uppercase;
		border-top: 1px solid rgba($cgp_white, 0.15);
		border-bottom: 1px solid rgba($cgp_white, 0.15);

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 20px;
			padding: 16px 0;
			border-bottom: none;
			position: relative;

			&:before {
				content: '';
				height: 1px;
				width: 50%;
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: rgba($cgp_white, 0.15);
			}
		}

		.title {
			color: $cgp_gold;
			line-height: 1.3;

			& > * {
				text-shadow: 0 0 20px rgba(0, 0, 0, 0.82);
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 18px;
			}
		}
	}

	// **************************************************

	&__text {
		max-width: 520px;
		margin-top: 16px;
		color: #fbfbfb;
		font-size: 18px;
		font-weight: 700;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 16px;
		}
	}

	// **************************************************

	&__btn {
		margin-top: 32px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 20px;
		}
	}

	// **************************************************

	&__table {
		background-color: $cgp_white;
		padding: 48px 48px 0;
		width: 100%;
		color: $cgp_blue;
		font-size: 18px;
		font-weight: 700;
		margin-top: 20px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 15px;
			padding: 16px 20px 0;
		}

		&-item {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid rgba($cgp_blue, 0.15);

			// Mobile
			@media #{$breakpoint-xs-only} {
				padding-bottom: 12px;
				margin-bottom: 12px;
			}

			&:last-of-type {
				border: none;
				margin: 0;
				padding: 0;

				// Mobile
				@media #{$breakpoint-xs-only} {
					border-bottom: 1px solid rgba($cgp_blue, 0.15);
				}
			}
		}

		&-footer {
			margin-top: 88px;
			display: flex;
			flex-wrap: wrap;
			gap: 24px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 36px;
			}
		}
	}

	&.secondary {
		padding-bottom: 0;

		&:before {
			bottom: 75px;
		}

		.hero-banner__bg img {
			height: calc(100% - 75px);
			bottom: auto;
			top: 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: calc(100% - 200px);
			}
		}

		.hero-banner__title {
			max-width: 100%;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 20px;
			}

			.title {
				// Mobile
				@media #{$breakpoint-sm-up} {
					font-size: 34px;
				}
				// Mobile
				@media #{$breakpoint-xs-only} {
					font-size: 22px;
				}
			}
		}

		.hero-banner__text {
			font-size: 18px;
			max-width: 820px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 16px;
			}

			strong {
				color: $cgp_gold;
				text-transform: uppercase;
			}
		}
	}
}
