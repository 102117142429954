// **************************************************
// List
// **************************************************

@import '../../../scss/import';

.list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		padding-left: 24px;
		position: relative;

		// ##################################################

		// Red arrow
		&::before {
			content: '·';
			left: 0;
			top: 0;
			position: absolute;
		}

		// ##################################################

		& + li {
			margin-top: 6px;
		}
	}

	// ##################################################

	&--secondary {
		li {
			&::before {
				content: '>';
			}
		}
	}

	// ##################################################

	&--numbered {
		list-style: decimal;
		padding-left: 32px;

		li {
			padding-left: 0;

			&:before {
				display: none;
			}
		}
	}
}
