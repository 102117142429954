// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	cursor: pointer;
	font-size: 16px;
	font-weight: 700;
	padding: 15px 32px;
	display: inline-grid;
	align-items: center;
	justify-content: center;
	grid-auto-flow: column;
	grid-gap: 22px;
	background-image: linear-gradient(to right, #004990 0%, #08427b 100%);
	color: $cgp_gold;
	text-transform: uppercase;
	letter-spacing: 0.16px;
	line-height: 1.4;
	transition: 0.3s ease;
	position: relative;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 14px 18px;
		font-size: 15px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to right, #d6ad68 0%, #e5c48d 100%);
		opacity: 0;
		transition: 0.3s ease;
	}

	// Hover/Focus
	&:hover,
	&:focus {
		color: $cgp_blue_dark;

		&:before {
			opacity: 1;
		}
	}

	&--secondary {
		background-image: linear-gradient(to right, #d6ad68 0%, #e5c48d 100%);
		color: $cgp_blue_dark;

		&:before {
			background-image: linear-gradient(to right, #004990 0%, #08427b 100%);
		}

		&:hover,
		&:focus {
			color: $cgp_gold;

			&:before {
				opacity: 1;
			}
		}
	}

	// ##################################################

	// Button text
	.btn__text {
		position: relative;
		display: block;
	}

	// ##################################################

	// Icon element
	.btn__icon {
		flex: 0 0 auto;
	}

	// Button with icon
	&--with-icon {
		.btn__icon {
			transform: translateX(0);
			transition: transform 0.25s ease-in;

			&:before {
				background-color: $cgp_white;
			}
		}
	}

	// Button with only icon (no text)
	// &.btn--only-icon {}

	// Icon aligned on left side
	&.icon-left {
		&:hover,
		&:focus {
			.btn__icon {
				transform: translateX(-6px);
			}
		}

		.btn__icon {
			order: 1;
		}
		.btn__text {
			order: 2;
		}
	}
}
