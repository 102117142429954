// ##################################################
// 0.0 Icons
// ##################################################

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--color-black);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&[class*='icon-arrow-'] {
		width: 10px;
		height: 10px;
	}

	// ##################################################

	&-arrow-right,
	&-arrow-left {
		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$arrow_right}');
			mask-image: url('data:image/svg+xml,#{$arrow_right}');
		}
	}

	// ##################################################

	&-arrow-left {
		&:before {
			transform: rotate(180deg);
		}
	}

	// ##################################################

	&[class*='icon-chevron-'] {
		width: 12px;
		height: 12px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-up {
		&:before {
			transform: rotate(180deg);
		}
	}

	// ##################################################

	&[class*='icon-chevron-left'],
	&[class*='icon-chevron-right'] {
		width: 10px;
		height: 18px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_left}');
			mask-image: url('data:image/svg+xml,#{$chevron_left}');
		}
	}

	// ##################################################

	&[class*='icon-chevron-right'] {
		&:before {
			transform: rotate(180deg);
		}
	}

	// ##################################################

	&-cross {
		width: 22px;
		height: 22px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_cross}');
			mask-image: url('data:image/svg+xml,#{$icon_cross}');
		}
	}

	// ##################################################

	&-search {
		width: 22px;
		height: 22px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_search}');
			mask-image: url('data:image/svg+xml,#{$icon_search}');
		}
	}

	// ##################################################

	&-search--white {
		width: 22px;
		height: 22px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_search--white}');
			mask-image: url('data:image/svg+xml,#{$icon_search--white}');
		}
	}

	// ##################################################

	&-facebook {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_facebook}');
			mask-image: url('data:image/svg+xml,#{$social_facebook}');
		}
	}

	// ##################################################

	&-instagram {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_instagram}');
			mask-image: url('data:image/svg+xml,#{$social_instagram}');
		}
	}

	// ##################################################

	&-twitter {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_twitter}');
			mask-image: url('data:image/svg+xml,#{$social_twitter}');
		}
	}

	// ##################################################

	&-youtube {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_youtube}');
			mask-image: url('data:image/svg+xml,#{$social_youtube}');
		}
	}

	// ##################################################

	&-linkedin {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_linkedin}');
			mask-image: url('data:image/svg+xml,#{$social_linkedin}');
		}
	}

	// ##################################################

	&-share {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_share}');
			mask-image: url('data:image/svg+xml,#{$icon_share}');
		}
	}

	// ##################################################

	&-mail {
		width: 24px;
		height: 24px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$social_email}');
			mask-image: url('data:image/svg+xml,#{$social_email}');
		}
	}

	// ##################################################

	&-info {
		width: 6px;
		height: 12px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_info}');
			mask-image: url('data:image/svg+xml,#{$icon_info}');
		}
	}

	// ##################################################

	&-plus {
		width: 40px;
		height: 40px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_plus}');
			mask-image: url('data:image/svg+xml,#{$icon_plus}');
		}
	}

	// ##################################################

	&-download {
		width: 18px;
		height: 18px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$download}');
			mask-image: url('data:image/svg+xml,#{$download}');
		}
	}

	// ##################################################

	&-send-email {
		width: 18px;
		height: 18px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$send_email}');
			mask-image: url('data:image/svg+xml,#{$send_email}');
		}
	}
}

// ##################################################
// 1.0 Icon colors
// ##################################################

.icon-color {
	&--white:before {
		background-color: white;
	}

	&--black:before {
		background-color: black;
	}

	&--red:before {
		background-color: red;
	}

	&--green:before {
		background-color: green;
	}

	&--blue:before {
		background-color: blue;
	}
}
