// **************************************************
// Form Module
// **************************************************

@import '../../../scss/import';

.form-module {
	position: relative;
	background-color: #fbfbfb;
	background-repeat: no-repeat;
	background-position: top 50px center;
	background-size: contain;

	// ##################################################

	.container {
		// Tablet L and Up
		@media #{$breakpoint-md-up} {
			max-width: 970px;
		}
	}

	// ##################################################

	&__top {
		padding: 180px 0 0;

		@media only screen and (max-width: 1441px) {
			padding: 120px 0 0;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 100px 0 0;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 64px 0 0;
		}
	}

	&__bottom {
		padding: 180px 0 240px;
		background-color: #fbfbfb;
		border-top: 1px solid rgba(#004990, 0.15);
		scroll-margin-top: -40px;

		@media only screen and (max-width: 1441px) {
			padding: 180px 0;
		}

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			padding: 180px 0;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 140px 0 160px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 90px 0 160px;
		}

		// ##################################################

		.title {
			text-align: center;

			// Mobile
			@media #{$breakpoint-xs-only} {
				br {
					display: none;
				}
			}
		}

		// ##################################################

		.container {
			opacity: 0.3;
			pointer-events: none;
		}

		// ##################################################

		&.is-ready {
			.container {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	// ##################################################

	&__final {
		max-width: 772px;
		margin: 0 auto;

		&-inputs {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 10px 20px;
			margin: 74px 0 40px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-template-columns: 1fr;
				margin: 40px 0 28px;
				grid-gap: 0;
			}

			.double {
				grid-column: 1 / 3;

				// Mobile
				@media #{$breakpoint-xs-only} {
					grid-column: 1 / 2;
				}
			}
		}

		.checkbox + .checkbox {
			margin-top: 24px;
		}

		&-submit {
			text-align: center;
			margin-top: 72px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 60px;
			}
		}
	}

	// ##################################################

	&__input {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 2fr 3fr;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: 1fr;
		}

		& + .form-module__input {
			margin-top: 40px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 80px;
			}
		}

		&-title {
			// Mobile
			@media #{$breakpoint-xs-only} {
				text-align: center;
			}
		}

		&-checkboxes {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 16px 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-gap: 20px;
				grid-template-columns: 1fr;
			}

			.fv-plugins-message-container {
				grid-column: 1 / 3;

				// Mobile
				@media #{$breakpoint-xs-only} {
					grid-column: 1 / 2;
				}
			}

			.checkbox {
				&__label {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					min-height: 60px;
					padding: 16px 20px;
					color: $cgp_blue_dark;
					background-color: $cgp_white;
					font-size: 18px;
					font-weight: 600;
					border: 1px solid $cgp_blue;
					transition: 0.3s ease;
					height: 100%;
					line-height: 1.3;

					// Mobile
					@media #{$breakpoint-xs-only} {
						padding: 12px 16px;
						font-size: 16px;
						margin: 0 auto;
						max-width: 280px;
					}

					&:before,
					&:after {
						display: none;
					}
				}
				input {
					&:checked ~ label {
						border-color: $cgp_gold;
						background-color: $cgp_gold;
					}
				}
			}
		}
	}

	// ##################################################

	&__next {
		margin-top: 80px;
		text-align: center;
		transform: translateY(50%);

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 0;
		}

		&-btn {
			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: calc(100% - 30px);
				margin: 0 auto;
			}
		}
	}

	// ##################################################

	.mod-input {
		max-width: 100%;
	}

	// ##################################################

	.g-captcha {
		margin-top: 20px;
	}

	// ##################################################

	&__thankyou {
		display: none;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_show_thankyou} {
		// stylelint-disable-next-line
		.form-module__form {
			display: none;
		}

		// stylelint-disable-next-line
		.form-module__thankyou {
			display: block;
		}
	}
}
