// **************************************************
// Radio button
// **************************************************

@import '../../../scss/import';

// Style
.radio-button {
	@include user-select;
	line-height: 1.5;

	// ##################################################

	&__label {
		position: relative;
		padding-left: 30px;
		cursor: pointer;
		margin: 0;
		display: block;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 25px;
		}

		// ##################################################

		&::before,
		&::after {
			content: '';
			width: 18px;
			height: 18px;
			position: absolute;
			left: 0;
			top: 4px;
			border-radius: 100%;
		}

		// ##################################################

		// Square
		&:before {
			border: 1px solid var(--color-black);
			transition: background-color 0.3s ease, box-shadow 0.3s ease;
		}

		// ##################################################

		// Check mark
		&::after {
			background: url('data:image/svg+xml,#{$icon_check }') no-repeat center / 12px;
			transform: scale(0);
			transition: transform 0.3s ease;
		}
	}

	// ##################################################

	input {
		@extend %sr_only;

		// ##################################################

		&:checked ~ label {
			// ##################################################

			&:before {
				background-color: var(--color-black);
			}

			// ##################################################

			&::after {
				transform: scale(1);
			}
		}

		&:focus-visible ~ label {
			&::before {
				box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px var(--color-grey-light);
			}
		}
	}

	// ##################################################
	// Without label

	&.hide-label {
		width: 18px;
		height: 18px;

		// ##################################################

		.radio-button__label {
			&::before,
			&::after {
				top: 0;
			}
		}

		// ##################################################

		.radio-button__text {
			@extend %sr_only;
		}
	}
}
