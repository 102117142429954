// **************************************************
// Tag
// **************************************************

@import '../../../scss/import';

.tag {
	line-height: normal;
	background-color: rgba($cgp_black, 0.1);
	padding: 5px 15px;
	border-radius: 20px;
	margin: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;

	// ##################################################

	&:hover {
		background-color: rgba($cgp_black, 0.15);
	}

	// ##################################################

	.icon {
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-right: 6px;
	}
}
