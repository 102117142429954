// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

.blue-bg {
	background-color: var(--color-darkblue);
	color: var(--color-white);
}

.card {
	position: relative;
	box-shadow: 0 0 16px rgba(9, 28, 46, 0.16);
	border-radius: 2px;
	background-color: var(--color-white);
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

// ##################################################
// TEXT
// ##################################################

.text {
	&--12 {
		@extend %fontsize12;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--16 {
		@extend %fontsize16;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--20 {
		@extend %fontsize20;
	}

	// ##################################################

	&--24 {
		@extend %fontsize24;
	}

	// ##################################################

	&--28 {
		@extend %fontsize28;
	}

	// ##################################################

	&--white {
		color: var(--color-white);
	}

	// ##################################################

	&--gray {
		color: var(--color-grey);
	}

	// ##################################################

	&--bold {
		font-weight: 700;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

.stretched-link {
	&::before {
		@extend %content;
		z-index: 3;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hidden {
	// Below 767px
	&-mobile {
		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}

	// ##################################################

	// Below 1023px
	&-tablet-p {
		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: none;
		}
	}
}

.visible {
	// Below 1013px
	&-mobile {
		display: none;

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: block;
		}
	}

	// ##################################################

	// Below 1023px
	&-tablet-p {
		display: none;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: block;
		}
	}
}

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}
