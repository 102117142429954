// **************************************************
// Menu
// **************************************************

@import '../../../scss/import';

.menu {
	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		position: fixed;
		top: 70px;
		height: calc(100% - 70px);
		z-index: 99;
		left: 0;
		width: 100%;
		background-color: var(--color-white);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;

		// stylelint-disable-next-line
		.#{$class_open_menu} & {
			opacity: 1;
			pointer-events: all;
		}
	}

	// ##################################################

	nav {
		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: block;
			max-width: calc(100% - 60px);
			margin: 0 auto;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: calc(100% - 32px);
		}
	}

	// ##################################################

	ul {
		font-size: 0;
		line-height: 0;
	}

	// ##################################################

	&__item {
		position: relative;
		display: inline-block;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			width: 100%;
		}

		// ##################################################

		&.level-1 {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				margin-left: 20px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				border-bottom: 1px solid rgba($cgp_black, 0.2);
			}
		}

		// ##################################################

		&.level-2 {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				width: 100%;
				padding: 8px 0;
				white-space: nowrap;
			}
		}

		// ##################################################

		&.has-submenu {
			& > .menu__link {
				cursor: pointer;

				// Tablet L and up
				@media #{$breakpoint-md-up} {
					padding-right: 20px;
				}
			}
		}

		// ##################################################

		// stylelint-disable-next-line
		&:hover,
		&.#{$class_active} {
			// stylelint-disable-next-line
			& > .menu__link {
				color: var(--color-grey);
			}
		}

		&:hover {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				& > .menu__dropdown {
					opacity: 1;
					pointer-events: all;
				}

				& > .menu__arrow {
					transform: rotate(-180deg);
				}
			}
		}
	}

	// ##################################################

	&__link {
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		position: relative;
		line-height: normal;
		z-index: 2;
		transition: color 0.3s ease;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 12px 0;
		}

		// ##################################################

		&--href {
			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				width: calc(100% - 40px);
			}
		}

		// ##################################################

		&--nohref {
			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				pointer-events: none;
			}
		}
	}

	// ##################################################

	&__arrow {
		position: absolute;
		right: 0;
		top: 6px;
		pointer-events: none;
		transition: transform 0.3s ease;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			height: 40px;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			pointer-events: all;
			padding: 0 7px;
			width: 100%;
		}

		// ##################################################

		// .without-href & {
		// 	// Tablet P and down
		// 	@media #{$breakpoint-sm-down} {
		// 		width: 100%;
		// 	}
		// }

		// ##################################################

		i {
			transition: transform 0.3s ease;

			// stylelint-disable-next-line
			.#{$class_open_submenu} & {
				transform: rotate(-180deg);
			}
		}
	}

	// ##################################################

	&__dropdown {
		// Tablet L and up
		@media #{$breakpoint-md-up} {
			display: block !important;
			position: absolute;
			left: -20px;
			top: 100%;
			z-index: 9;
			padding-top: 30px;
			will-change: opacity;
			opacity: 0;
			margin-top: -1px;
			pointer-events: none;
			transition: opacity 0.3s ease;

			// ##################################################

			ul {
				background-color: var(--color-white);
				padding: 8px 20px;
				box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
			}
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: none;
			padding: 0 0 10px 15px;
		}

		// ##################################################

		// stylelint-disable-next-line
		.#{$class_open_submenu} & {
			display: block;
		}
	}
}
