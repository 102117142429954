//*******************************************************************************
// IMAGES
//*******************************************************************************

@import '../../../scss/import';

.image-aspect {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		position: relative;
		height: 0;
		width: 100%;
		padding-bottom: calc(100% / (var(--aspect-ratio)));
	}

	// ##################################################

	& > * {
		@extend %abs_pos_fullwidth;
	}

	// ##################################################

	figure {
		margin: 0;
	}

	// ##################################################

	img,
	picture {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	// ##################################################

	// Cover image
	&--cover {
		@extend %abs_pos_fullwidth;
		position: absolute;

		&::before {
			display: none;
		}
	}
}
