// **************************************************
// Modal
// **************************************************

@import '../../../scss/import';

// Style
.modal {
	margin: auto;
	max-width: 1000px;
	width: 100%;

	&__close {
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 20px;
		right: 24px;
		background: url('data:image/svg+xml,#{$close_modal }') no-repeat center / contain;
		cursor: pointer;
		transition: 0.3s ease;

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 16px;
			right: 10px;
			height: 24px;
			width: 24px;
		}

		&:hover {
			opacity: 0.5;
		}
	}

	// **************************************************

	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		overflow: auto;
		height: $vh_100;
		background-color: $cgp_blue;
		padding: 44px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s ease;
		opacity: 0;
		pointer-events: none;
		z-index: $zindex_modal;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 20px 0;
		}

		body.is-open-modal & {
			opacity: 1;
			pointer-events: auto;
		}
	}

	// **************************************************

	&__img {
		width: 100%;
		position: relative;
		padding: 32px 20px 32px 80px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		color: $cgp_white;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			padding: 28px 20px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 20px 16px 28px;
		}

		&-logo {
			max-width: 220px;
			margin-bottom: 40px;
			position: relative;
			z-index: 1;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 140px;
				margin-bottom: 220px;
			}
		}

		&:before {
			position: absolute;
			transform: rotate(-180deg);
			background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
			opacity: 0.79;
			content: '';
			bottom: 0;
			left: 0;
			width: 100%;
			height: 33%;
			z-index: 0;
		}

		.title {
			color: $cgp_white;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
			max-width: 300px;
			width: 100%;
			position: relative;
			line-height: 1;

			// Tablet L and up
			@media #{$breakpoint-md-up} {
				font-size: 34px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 100%;
				font-size: 26px;
			}
		}

		&-text {
			margin-top: 32px;
			font-size: 20px;
			font-weight: 700;
			max-width: 340px;
			text-transform: uppercase;
			letter-spacing: 0.4px;
			position: relative;
			padding-top: 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 20px;
				font-size: 18px;
			}

			&:before {
				content: '';
				height: 1px;
				width: 60%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba($cgp_white, 0.15);
			}
		}

		&-bg {
			img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				pointer-events: none;
				object-fit: cover;
			}

			&.destkop {
				opacity: 1;

				// Mobile
				@media #{$breakpoint-xs-only} {
					opacity: 0;
				}
			}
			&.mobile {
				opacity: 0;

				// Mobile
				@media #{$breakpoint-xs-only} {
					opacity: 1;
				}
			}
		}
	}

	// **************************************************

	&__title {
		color: $cgp_blue;
		font-weight: 800;
		font-size: 26px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 24px;
		}
	}

	// **************************************************

	&__content {
		width: 100%;
		padding: 52px 20px 72px;
		background-color: #fbfbfb;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 36px;
		flex-direction: column;

		// Mobile
		@media #{$breakpoint-md-down} {
			padding: 48px 20px 72px;
		}
	}
}
