// ##################################################
// Colors
// ##################################################
:root {
	--color-white: #fff;
	--color-black: #000;
	--color-grey: #616575;
	--color-grey-dark: #696969;
	--color-grey-light: #c1c1c1;
	--color-purple: #7464ac;
	--color-darkblue: #091c2e;
	--color-orange: #f89c4f;
	--color-green: #24a148;
	--color-primary: #c53319;
	--color-primary-light: rgba(197, 51, 25, 0.5);
	--color-secondary: #deb887;
	--color-border: rgba(8, 28, 47, 0.2);
	--color-success: #28a745;
	--color-danger: #c82333;
	--color-warning: #ffc107;
	--color-info: #17a2b8;
	--color-validation: #ff3b30;
	--color-alert-primary: #cce5ff;
	--color-alert-secondary: #e2e3e5;
	--color-alert-success: #d4edda;
	--color-alert-danger: #f8d7da;
	--color-alert-warning: #fff3cd;
	--color-focus: #6495ed;
	--space-16px: 16px;
	--space-24px: 24px;
	--space-40px: 40px;
	--space-60px: 60px;
	--space-80px: 80px;
	--space-100px: 100px;
	--space-120px: 120px;
}
