// **************************************************
// lightbox-gallery
// **************************************************

@import '../../../scss/import';

// Style
.gallery-modal {
	position: fixed;
	top: 100vh; // We hide it from viewport to prevent browser loading images (they are set to lazyload))
	left: 0;
	height: $vh_100;
	width: 100%;
	@extend %flex_center_center;
	background-color: var(--color-white);
	z-index: $zindex_modal;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	visibility: hidden;
	transition: opacity 0.3s ease, visibility 0.3s ease, top 0s linear 0.5s;

	// stylelint-disable-next-line
	&.#{$class_open_gallery_modal} {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		top: 0;
		transition: opacity 0.3s ease, visibility 0.3s ease, top 0s linear 0s;
	}

	// ##################################################

	&__inner {
		@include user-select;
		position: relative;
		margin: auto;
		width: 100%;
		z-index: $zindex-modal;
		transform: scale(0.5);
		opacity: 0.5;
		transition: transform 0.5s ease, opacity 0.5s ease;

		// stylelint-disable-next-line
		.#{$class_open_gallery_modal} & {
			opacity: 1;
			transform: scale(1);
		}
	}

	// ##################################################

	&__slide {
		height: calc(var(--vh, 1vh) * 100 - 170px);

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: calc(var(--vh, 1vh) * 100 - 100px);
			padding-top: 42px;
		}
	}

	// ##################################################

	&__image {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;

		// ##################################################

		img {
			object-fit: cover;
			width: auto;
			max-height: 100%;

			.editor-text & {
				margin: 0;
			}
		}

		// ##################################################

		&-caption {
			z-index: 3;
			display: flex;
			align-self: flex-start;
			margin-top: 25px;

			// ##################################################

			p {
				font-style: italic;
				font-size: 13px;

				.editor-text & {
					margin: 0;
				}
			}
		}
	}

	// ##################################################

	&__close {
		position: absolute;
		top: 2px;
		right: 52px;
		z-index: $zindex-modal + 1;
		transition: transform 0.4s ease;
		cursor: pointer;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			right: 0;
		}
	}

	// ##################################################

	&-slider-wrapper {
		padding: 0 100px;

		// Tablet L
		@media #{$breakpoint-md-only} {
			padding: 0 80px;
		}

		// Tablet P only
		@media #{$breakpoint-sm-only} {
			padding: 0 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 0 20px;
		}
	}

	// ##################################################

	.container {
		position: relative;
	}

	// ##################################################

	.swiper {
		overflow: visible;
	}

	// ##################################################

	.slide-number {
		color: var(--color-grey-light);
		margin-right: 30px;
	}
}

// ##################################################
// Slider arrow
// ##################################################

.slider-arrow {
	// ##################################################

	&__item {
		position: absolute;
		top: 0;
		width: 100px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: $zindex-modal;

		i {
			transition: transform 0.4s ease;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 20px;
			}
		}

		// ##################################################

		&.arrow-left {
			left: 0;
		}

		// ##################################################

		&.arrow-right {
			right: 0;
		}
	}
}
