//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 18px;
	line-height: 1.3;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%fontsize120 {
	font-size: 120px;
	line-height: 1;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 80px;
	}
}

// ##################################################

%fontsize28 {
	font-size: 28px;
	line-height: 1.39;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 20px;
		line-height: 1.5;
	}
}

// ##################################################

%fontsize24 {
	font-size: 24px;
	line-height: 1.5;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
		line-height: 1.83;
	}
}

// ##################################################

%fontsize20 {
	font-size: 20px;
	line-height: 1.6;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize18 {
	font-size: 18px;
	line-height: 1.3;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%fontsize16 {
	font-size: 16px;
	line-height: 1.5;
}

// ##################################################

%fontsize14 {
	font-size: 14px;
	line-height: 1.79;
}

// ##################################################

%fontsize12 {
	font-size: 12px;
}

// ##################################################

%title {
	color: $cgp_blue_dark;
	font-weight: 700;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 42px;
	font-weight: 700;
	line-height: 1.2;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 36px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 32px;
	}
}

// ##################################################

%title_h2 {
	@extend %title;
	font-size: 22px;
	font-weight: 700;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 20px;
	}
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 19px;
	}
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 19px;
	font-weight: 700;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 18px;
	font-weight: 700;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 14px;
	}
}

// ##################################################
// Focus styles
// ##################################################

%focus_style {
	outline-color: var(--color-focus);
	outline-style: solid;
	outline-offset: 0;
	outline-width: 2.5px;
	transition: outline-offset 0.2s linear;
}

// ##################################################
// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
}

// ##################################################
// ##################################################

%page_template_top_padding {
	overflow: hidden;
	position: relative;
	padding-top: 138px;

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		padding-top: 80px;
	}
}

// ##################################################
// Margins
// ##################################################

%margin-160 {
	margin-bottom: 160px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 120px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 180px;
	}
}

%margin-120 {
	margin-bottom: 120px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 60px;
	}
}

%margin-100 {
	margin-bottom: 100px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 60px;
	}
}

%margin-80 {
	margin-bottom: 100px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 60px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 40px;
	}
}

%margin-60 {
	margin-bottom: 60px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 40px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 30px;
	}
}

%margin-40 {
	margin-bottom: 40px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 20px;
	}
}

%margin-30 {
	margin-bottom: 30px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 20px;
	}
}
