// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

// Style
.checkbox {
	@include user-select;
	line-height: 1.5;

	// ##################################################

	&__label {
		position: relative;
		padding-left: 44px;
		cursor: pointer;
		margin: 0;
		display: block;
		color: $cgp_blue;
		font-size: 14px;
		font-weight: 400;

		// ##################################################

		&::before,
		&::after {
			content: '';
			width: 22px;
			height: 22px;
			position: absolute;
			left: 0;
			top: 0;
		}

		// ##################################################

		// Square
		&:before {
			border: 1px solid rgba(0, 73, 144, 0.4);
			transition: background-color 0.3s ease, box-shadow 0.3s ease;
		}

		// ##################################################

		// Check mark
		&::after {
			background: url('data:image/svg+xml,#{$icon_check }') no-repeat 6px / 11px;
			transform: scale(0);
			transition: transform 0.3s ease;
		}
	}

	// ##################################################

	input {
		@extend %sr_only;

		// ##################################################

		&:checked ~ label {
			// ##################################################
			&::after {
				transform: scale(1);
			}
		}

		&:focus-visible ~ label {
			&::before {
				box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px var(--color-grey-light);
			}
		}
	}

	// ##################################################

	a {
		text-decoration: underline;
	}

	// ##################################################
	// Without label

	&.hide-label {
		width: 18px;
		height: 18px;

		// ##################################################

		.checkbox__label {
			&::before,
			&::after {
				top: 0;
			}
		}

		// ##################################################

		.checkbox__text {
			@extend %sr_only;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed},
	// stylelint-disable-next-line
	.#{$class_validation_failed} & {
		// stylelint-disable-next-line
		.checkbox__label {
			// stylelint-disable-next-line
			&::before {
				border-color: var(--color-validation);
			}
		}
	}

	// ##################################################

	.fv-plugins-message-container {
		text-align: left;
		padding-left: 44px;
	}
}
