// ##################################################
// Language Switch
// ##################################################

@import '../../../scss/import';

// Style
.language-switch {
	position: relative;
	display: inline-block;
	font-size: 16px;

	// Tablet P and Up
	@media #{$breakpoint-sm-up} {
		margin-left: 20px;
	}

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		width: 100%;
	}

	// ##################################################

	&.has-submenu {
		& > .language-switch__link {
			cursor: pointer;

			// Tablet L and up
			@media #{$breakpoint-md-up} {
				padding-right: 20px;
			}
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&:hover,
	&.#{$class_active} {
		// stylelint-disable-next-line
		& > .language-switch__link {
			color: $cgp_grey;
		}
	}

	&:hover {
		// Tablet L and up
		& > .language-switch__dropdown {
			opacity: 1;
			pointer-events: all;
		}
		& > .language-switch__arrow {
			transform: rotate(-180deg);
		}
	}

	// ##################################################

	&__link {
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		position: relative;
		line-height: normal;
		z-index: 2;
		transition: color 0.3s ease;
	}

	&__item {
		.language-switch__link {
			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				padding: 6px 0;
			}
		}
	}

	// ##################################################

	&__arrow {
		position: absolute;
		right: 0;
		top: 6px;
		pointer-events: none;
		transition: transform 0.3s ease;

		// ##################################################

		i {
			transition: transform 0.3s ease;

			// stylelint-disable-next-line
			.#{$class_open_submenu} & {
				transform: rotate(-180deg);
			}
		}
	}

	// ##################################################

	&__dropdown {
		z-index: 100;
		display: block !important;
		position: absolute;
		left: -20px;
		top: 100%;
		padding-top: 30px;
		will-change: opacity;
		opacity: 0;
		margin-top: -1px;
		pointer-events: none;
		transition: opacity 0.3s ease;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			padding-top: 10px;
		}

		// ##################################################

		ul {
			background-color: $cgp_white;
			padding: 8px 20px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		}
	}
}
