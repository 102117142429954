#wp-admin-bar-sp-role-switcher span {
	&.on {
		background-color: #d63638;
		display: inline-block;
		box-sizing: border-box;
		margin: 1px 0 -1px 2px;
		padding: 0 5px;
		min-width: 18px;
		height: 18px;
		border-radius: 9px;
		color: #fff;
		font-size: 11px;
		line-height: 1.6;
		text-align: center;
		z-index: 26;

		&:before {
			content: '\f177';
			font-family: 'Dashicons'; /* stylelint-disable-line */
		}
	}

	&.off:before {
		content: '\f530';
		font-family: Dashicons; /* stylelint-disable-line */
	}
}

#wp-admin-bar-sp-role-switcher.menupop.hover ul li.sp-switch-back a {
	color: #d63638;
}
