// **************************************************
// Input
// **************************************************

@import '../../../scss/import';

.mod-input {
	margin-bottom: 16px;
	width: 100%;
	position: relative;
	font-size: 18px;
	line-height: 1.44;
	font-weight: 400;

	// Tablet P and Down
	@media #{$breakpoint-sm-down} {
		font-size: 16px;
	}

	// ##################################################

	&__label {
		position: relative;
		display: block;
		margin-bottom: 6px;
		line-height: 1.25;
		font-size: 18px;
		font-weight: 500;
		padding-left: 4px;
	}

	// ##################################################

	&__field {
		position: relative;
		width: 100%;
		height: 60px;
		border: 1px solid rgba(0, 73, 144, 0.4);
		background-color: #ffffff;
		outline: none;
		z-index: 1;
		padding: 12px 28px;
		transition: border-color 0.3s ease;
		font-size: 18px;
		font-weight: 700;
		color: $cgp_blue_dark;

		&::placeholder {
			color: $cgp_blue_dark;
			font-weight: 400;
		}

		// ###########################################################

		&:focus {
			outline: none;
			border-color: $cgp_gold;
		}

		// ###########################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} &,
		&.#{$class_validation_failed} {
			color: $cgp-validation-failed;
			border-color: $cgp-validation-failed;
		}
	}

	// ##################################################

	textarea {
		resize: none;
		height: 142px;
		padding: 12px 22px;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		opacity: 0.5;

		// stylelint-disable-next-line
		.mod-input__field {
			pointer-events: none;
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	// ###########################################################

	&--textarea {
		height: auto;

		.mod-input__field {
			padding: 20px;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				padding: 15px;
			}
		}
	}

	// ###########################################################

	&.has-icon {
		height: auto;
		border: 0;

		// ###########################################################

		.mod-input__icon {
			background-color: var(--color-grey-light);
			position: absolute;
			left: -1px;
			top: 0;
			width: 60px;
			height: 100%;
			pointer-events: none;
			z-index: 3;
			border-radius: 30px 0 0 30px;
		}

		// ###########################################################

		.mod-input__field {
			padding-left: 75px;
		}

		// ###########################################################

		// stylelint-disable-next-line
		&.#{$class_validation_failed} {
			// stylelint-disable-next-line
			.mod-input__icon {
				background-color: $cgp-validation-failed;
			}
		}
	}
}

// ###########################################################

.fv-plugins-message-container,
.validation-message {
	color: $cgp-validation-failed;
	position: relative;
	font-size: 14px;
	margin-top: 4px;
	text-align: left;
}

.validation-message {
	display: none;

	// stylelint-disable-next-line
	.#{$class_validation_failed} & {
		display: block;
	}
}
