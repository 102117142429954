//**********************************************************
// LEAD TEXT
//**********************************************************

@import '../../../scss/import';

.lead-text {
	@extend %fontsize20;
	font-weight: 700;
}
