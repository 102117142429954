//*******************************************************************************
// 1.0 - WEBFONTS : Nunito
//*******************************************************************************

@font-face {
	font-family: 'Nunito Sans';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NunitoSans10pt-Regular.woff2') format('woff2'),
		url('../fonts/NunitoSans10pt-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NunitoSans10pt-Medium.woff2') format('woff2'),
		url('../fonts/NunitoSans10pt-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito Sans';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NunitoSans10pt-Bold.woff2') format('woff2'),
		url('../fonts/NunitoSans10pt-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NunitoSans10pt-ExtraBold.woff2') format('woff2'),
		url('../fonts/NunitoSans10pt-ExtraBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NunitoSans10pt-Light.woff2') format('woff2'),
		url('../fonts/NunitoSans10pt-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
