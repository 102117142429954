// **************************************************
// Site Header
// **************************************************

@import '../../../scss/import';

// Style
.header-main {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 24px 0;
	transition: 0.2s;
	z-index: $zindex_header;
	display: flex;
	justify-content: center;
	gap: 28px;
	align-items: center;

	// ##################################################

	.container {
		flex-shrink: 0;
	}

	// ##################################################

	&__logo {
		max-width: 130px;
		flex-shrink: 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 112px;
		}
	}

	// ##################################################

	&__gb-logo {
		max-width: 220px;
		flex-shrink: 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 150px;
		}
	}

	// ##################################################

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 28px;
	}

	// ##################################################

	&__line {
		height: 1px;
		width: 100%;
		background-color: rgba($cgp_white, 0.15);
	}
}
