// ##################################################
// Cookie banner
// ##################################################

@import '../../../scss/import';

// Style
.advance-cookie-banner {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: $zindex_cookie_notice;
	box-shadow: $box_shadow_secondary;
	color: $cgp_black;
	transition: opacity 0.5s ease;
	padding: 30px;
	opacity: 0;
	pointer-events: none;
	max-height: $vh_100;
	overflow: auto;

	// If Cookie is not set
	// stylelint-disable-next-line
	.#{$class_cookie_not_set} & {
		opacity: 1;
		pointer-events: all;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 20px;
	}

	// ##################################################

	&__inner {
		position: relative;
		border-radius: 6px;
		z-index: 3;
		display: grid;
		grid-template-columns: 1fr 200px;
		grid-gap: 20px;
		align-items: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	// ##################################################

	&__detail-btn {
		font-weight: 500;
	}

	// ##################################################

	&__button {
		margin-left: auto;
	}

	// ##################################################

	&__details {
		margin-top: 20px;
		display: none;

		// stylelint-disable-next-line
		.#{$class_open_details} & {
			display: block;
		}

		// ##################################################

		li {
			padding-left: 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				padding-left: 10px;
			}

			// ##################################################

			& + li {
				margin-top: 20px;
			}
		}

		// ##################################################

		.checkbox {
			strong {
				display: block;
				font-size: 14px;
			}

			// ##################################################

			&__text {
				@extend %fontsize12;
			}
		}
	}

	// ##################################################

	.hide-btn-text {
		display: none;
	}

	// ##################################################

	.btn--cookie {
		cursor: pointer;
		padding: 10px 20px;
		min-width: 112px;
		min-height: 38px;
		border-radius: 5px;
		background-color: var(--color-primary);
		color: var(--color-white);
		text-transform: uppercase;
		font-weight: 300;
		line-height: 20px;
		transition: color 0.3s ease, background-color 0.3s ease, width 0.3s ease;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_open_details} {
		// stylelint-disable-next-line
		.normal-btn-text {
			display: none;
		}

		// stylelint-disable-next-line
		.hide-btn-text {
			display: inline-block;
		}
	}
}

// ##################################################
// Advance Cookie Modal
// ##################################################

.advance-cookie-modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	@extend %flex_center_center;
	background-color: rgba($cgp_white, 0.8);
	z-index: $zindex_modal;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s ease;
	overflow: auto;

	// stylelint-disable-next-line
	&.#{$class_open_modal} {
		opacity: 1;
		pointer-events: all;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 40px 0;
	}

	// ##################################################

	&-toggle-btn {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		z-index: $zindex_cookie_notice;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 8px 10px;
		text-transform: uppercase;
		color: var(--color-white);
		background-color: rgba($cgp_black, 0.5);
		border-radius: 5px 5px 0 0;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
		@extend %fontsize12;
		transition: transform 0.4s ease;

		// stylelint-disable-next-line
		.#{$class_cookie_not_set} & {
			transform: translateY(100%);
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			left: auto;
		}
	}

	// ##################################################

	&__inner {
		padding: 50px 65px;
		border-radius: 6px;
		background-color: var(--color-white);
		max-width: 780px;
		position: relative;
		width: 100%;
		box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
		margin: auto;
		transform: scale(0.5);
		opacity: 0.5;
		transition: transform 0.5s ease, opacity 0.5s ease;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			max-width: calc(100% - 40px);
			padding: 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 40px 20px;
		}

		// is-open-modal
		// stylelint-disable-next-line
		.#{$class_open_modal} & {
			opacity: 1;
			transform: scale(1);
		}
	}

	// ##################################################

	&__close-btn {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	// ##################################################

	&__header {
		padding-bottom: 50px;
		border-bottom: 3px solid #b4b4b4;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-bottom: 20px;
		}

		// ##################################################

		.title {
			margin-bottom: 40px;
			color: #444;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 15px;
			}
		}
	}

	// ##################################################

	&__details {
		padding-top: 20px;

		// ##################################################

		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			grid-gap: 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				display: block;
			}

			// ##################################################

			&:not(:first-child) {
				margin-top: 20px;
			}
		}
	}

	// ##################################################

	&__selector {
		border-radius: 5px;
		display: inline-block;
		padding: 5px 28px 3px 10px;
		position: relative;
		background-image: linear-gradient(45deg, transparent 50%, #212529 50%),
			linear-gradient(135deg, #212529 50%, transparent 50%);
		background-position: calc(100% - 12px) calc(1em + 0px), calc(100% - 7px) calc(1em + 0px);
		background-size: 7px 5px, 5px 5px, 1px 1.5em;
		background-repeat: no-repeat;
		cursor: pointer;
		outline: 0;
		font-weight: 300;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 20px;
			width: 100%;
		}

		// ##################################################

		&:focus {
			background-color: var(--color-white);
			background-image: linear-gradient(45deg, #212529 50%, transparent 50%),
				linear-gradient(135deg, transparent 50%, #212529 50%);
			background-position: calc(100% - 7px) 1em, calc(100% - 12px) 1em;
			background-size: 5px 5px, 7px 5px, 1px 1.5em;
			background-repeat: no-repeat;
		}

		// ##################################################

		&-option {
			font-weight: 300;
		}
	}
}
