//*******************************************************************************
// Testinmonial
//*******************************************************************************

@import '../../../scss/import';

.testimonial {
	max-width: 710px;

	// ##################################################

	.testimonials & {
		max-width: 100%;
	}

	// ##################################################

	blockquote {
		margin: 0;
	}

	// ##################################################

	footer {
		margin-top: 15px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 25px;
		}
	}

	// ##################################################

	&__img {
		position: relative;
		display: block;
		width: 100px;
		height: 100px;
		margin: 0 auto 16px;
		border-radius: 100%;
		overflow: hidden;
	}
}
