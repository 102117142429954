//**********************************************************
// ARROW [ Default white]
//**********************************************************

@import '../../../scss/import';

.arrow {
	width: 32px;
	height: 32px;
	border: 1px solid var(--color-black);
	position: relative;
	cursor: pointer;
	border-radius: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;

	.icon {
		pointer-events: none;
	}

	// ##################################################

	&--large {
		// Tablet L and up
		@media #{$breakpoint-md-up} {
			width: 50px;
			height: 50px;

			.icon[class*='icon-arrow-'] {
				width: 15px;
				height: 15px;
			}
		}
	}

	// ##################################################

	&:hover {
		background-color: var(--color-black);
		box-shadow: 0 0 0 3px rgba($cgp_black, 0.2);

		.icon::before {
			background-color: var(--color-white);
		}
	}
}
