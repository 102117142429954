//*******************************************************************************
// 0.2 - GENERAL : Text styles
//*******************************************************************************
// General focus style

:focus {
	outline: none;
}

a,
button,
input,
textarea,
.btn,
.focus-visible,
.focus-within {
	outline-offset: 6px;

	&:focus-visible {
		@extend %focus_style;
	}
}

input:focus {
	@extend %focus_style;
}
